import React, {useState,useEffect,Fragment} from 'react'
import axios from 'axios'
import {Form,Input,InputGroup,InputGroupAddon,InputGroupText,Button,Collapse,Alert} from 'reactstrap'
import {FaAngleDoubleDown,FaAngleDoubleUp} from 'react-icons/fa'

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

const cl = console.log


const contactEndpoint = process.env.NODE_ENV==='production'?'contact':'http://localhost:5000/contact'


// Remember that the hook can only be used within a React functional component
	const SubmitContactFormButton  =  props => {
	const {formSending,setFormSending,setFormSubmitted,formState,setFormResponse} = props
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [token,setToken] = useState(false)
	const clickHandler = async () => {
		if(!executeRecaptcha){return}
		setToken(await executeRecaptcha("contact"))
	}

	useEffect(()=>{
		if(token){
			setFormSending(true)
			axios.post(contactEndpoint,{formState,token})
			.then(res=>{
				setFormSending(false)
				setFormSubmitted(true)
				setFormResponse(res.data)
			})
			.catch(err=>{
				setFormSending(false)
				setFormResponse(err.message)
			})
		}
	},[token])
	return <div className="mt-3">
				<Button color="yellow" className="mt-3" onClick={clickHandler} disabled={formSending}>Submit Contact</Button>
				{formSending&&<div className="mt-3 ml-3"><div className="text-yellow spinner-border" role="status">
  <span class="sr-only">Sending...</span>
</div></div>}
	</div>
	}



const ContactForm = props => {

	const [formState,setFormState]=useState({})
	const [formOpen,setFormOpen] = useState(false)
	const [formSending,setFormSending] = useState(false)
	const [formResponse,setFormResponse] = useState({status:null,res:null,})
	const [formSubmitted,setFormSubmitted] = useState(false)
	
	const handleFieldChange = e => {
		e.preventDefault()
		const {value,name} = e.target
		const newState = {...formState,[name]:value}
		setFormState(newState)
	}


	const toggleContact = () => setFormOpen(lastState=>!lastState)

	useEffect(()=>{
		if(formResponse.status!==null){
			setFormSubmitted(true)
		}
	},[formResponse])

	return (
	<div>
	    {!formSubmitted?
	    <Fragment>
	    <h3>Get In Touch <Button color={formOpen?'yellow':'primary'} className={formOpen?'':'text-yellow border-yellow'} onClick={toggleContact} size="sm">{formOpen?<FaAngleDoubleUp/>:<FaAngleDoubleDown/>}</Button></h3>
	    <Collapse isOpen={formOpen}>

		    <Form onSubmit={e=>e.preventDefault()} disabled={formSending}>
		      
		      <InputGroup>
		        <InputGroupAddon addonType="prepend">
		          <InputGroupText>Name</InputGroupText>
		        </InputGroupAddon>
		        <Input type="text" placeholder="Your Name" name="name" onChange={handleFieldChange} value={formState.name||''} disabled={formSending}/>
		      </InputGroup>

		      <InputGroup className="mt-3">
		        <InputGroupAddon addonType="prepend">
		          <InputGroupText>Email</InputGroupText>
		        </InputGroupAddon>
		        <Input type="email" placeholder="Email Address" name="email" onChange={handleFieldChange} value={formState.email||''} disabled={formSending}/>
		      </InputGroup>

		      <InputGroup className="mt-3">
		        <InputGroupAddon addonType="prepend">
		          <InputGroupText>Note</InputGroupText>
		        </InputGroupAddon>
		        <Input type="textarea" placeholder="Type your message here" name="note" onChange={handleFieldChange} value={formState.note||''} disabled={formSending}/>
		      </InputGroup>
		      <GoogleReCaptchaProvider reCaptchaKey="6LfzcfMUAAAAAIf9jltAIXhATRh1zw4nJJ3DGRC2">
		      	<SubmitContactFormButton {...{formSending,setFormSending,formSubmitted,setFormSubmitted,setFormResponse,formState}}/>
		      </GoogleReCaptchaProvider>
		    </Form>
		</Collapse>
		</Fragment>
	:null
	}
		<Alert className="mt-3" isOpen={formSubmitted} color={formResponse.status?'success':'danger'}>
			{formResponse.res!==null?formResponse.res.message?formResponse.res.message:formResponse.res:null}
		</Alert>
	</div>

)}

export default ContactForm