import React from 'react'
import ContactForm from './_comps/ContactForm'
import './css/App.css'

import WebFont from 'webfontloader'

const cl = console.log


// get the Google fonts
WebFont.load({
  google: {
    families: ['Montserrat:300,400,700,900', 'OpenSans:400:700','sans-serif']
  }
})

function App() {
  return (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center text-center">
    <h1><strong>frankly.io</strong></h1>
    <p>We are currently hard at work designing:<br/>
    <strong>On Demand</strong> remote interviews with purpose!</p>
    <ContactForm/>
  </div>
  );
}

export default App;
